import { useEffect } from 'react';
//import './App.css';
import '@n8n/chat/style.css';
import './custom-chat-styles.css';
import { createChat } from '@n8n/chat';

function App() {
  useEffect(() => {
    createChat({
      webhookUrl: 'https://autonomousagents.app.n8n.cloud/webhook/0cf81bc7-9fde-4054-ba4a-f5ea7381fe4e/chat',
      webhookConfig: {
        method: 'POST',
        headers: {}
      },
      target: '#n8n-chat',
      mode: 'fullscreen',
      chatInputKey: 'chatInput',
      chatSessionKey: 'sessionId',
      metadata: {},
      showWelcomeScreen: false,
      defaultLanguage: 'en',
      initialMessages: [
        'Hi, my name is Aiman Lee. How can I assist you?'
      ],
      i18n: {
        en: {
          title: 'Welcome to Aircon Hero! 👋',
          subtitle: "Start a chat with our customer service agents 24/7.",
          footer: '',
          getStarted: 'New Conversation',
          inputPlaceholder: 'Type your question..',
        },
      },
    });
  }, []);

  return (<div></div>);
}

export default App;
